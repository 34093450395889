import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {useFrame} from "@react-three/fiber";
const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}/tom-fight-ready.glb`;

type GLTFResult = GLTF & {
  nodes: {
    ZBrush_defualt_group: THREE.SkinnedMesh
    mixamorigHips: THREE.Bone
  }
  materials: {
    defaultMat: THREE.MeshStandardMaterial
  }
  // animations: GLTFAction[]
}

type ActionName = 'Armature|Armature|mixamo.com|Layer0'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['skinnedMesh'] | JSX.IntrinsicElements['bone']>>

export function TomFightReady({ mode, lockKeyboard, setHintMessage, hintMessage, setShowHintBox, playerPosition, setPlayerPosition, scale, rotation, position, myPositionRef, finalPlacement }: any) {
  const group = useRef<THREE.Group>(null!)
  const { nodes, materials, animations } = useGLTF(ITEM_URI) as GLTFResult
  // @ts-ignore
  // const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(4);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });

  return (
    <group scale={scale} position={position} rotation={rotation} ref={group}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.04}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh name="ZBrush_defualt_group" geometry={nodes.ZBrush_defualt_group.geometry} material={materials.defaultMat} skeleton={nodes.ZBrush_defualt_group.skeleton} />
        </group>
      </group>
    </group>
  )
}
